import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="password-toggle"
export default class extends Controller {
  static targets = ["passwordField"]

  toggle(event) {
    const button = event.currentTarget;
    const password_field = button.previousElementSibling;

    if (password_field.type === "password") {
      password_field.type = "text";
    } else {
      password_field.type = "password";
    }
  }
}
